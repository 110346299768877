<template>
  <svg viewBox="0 0 22 27" :width="size">
    <switch>
      <g>
        <path
          d="M16.1 8.9H5.8c-.3 0-.6.3-.6.6s.3.6.6.6h10.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zM16.1 11.9H5.8c-.3 0-.6.3-.6.6s.3.6.6.6h10.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zM16.1 15H5.8c-.3 0-.6.3-.6.6s.3.6.6.6h10.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zM16.1 18.1H5.8c-.3 0-.6.3-.6.6s.3.6.6.6h10.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6z"
        />
        <path
          d="M18.2 1.8c-.3 0-.6.3-.6.6 0 .4.2.7.6.7 1 0 1.7.8 1.7 1.8v17.9c0 1-.8 1.8-1.8 1.8H3.5c-1 0-1.8-.8-1.8-1.8V4.9c0-1 .8-1.8 1.7-1.8.4 0 .6-.4.6-.7 0-.3-.3-.6-.6-.6C1.7 1.8.3 3.2.3 4.9v17.9c0 1.7 1.4 3.1 3.1 3.1H18c1.7 0 3.1-1.4 3.1-3.1V4.9c.1-1.7-1.2-3.1-2.9-3.1z"
        />
        <path
          d="M18.1 26.3H3.5c-2 0-3.5-1.6-3.5-3.5V4.9C0 3 1.6 1.4 3.5 1.4c.6.1 1 .5 1.1 1 0 .3-.1.6-.3.8-.2.2-.4.3-.7.3-.7 0-1.3.6-1.3 1.3v17.9c0 .7.6 1.3 1.3 1.3h14.6c.7 0 1.3-.6 1.3-1.3V4.9c0-.7-.6-1.3-1.3-1.3-.3 0-.6-.2-.8-.4-.2-.2-.3-.5-.3-.8 0-.5.4-.9.9-1h.1c1.9 0 3.5 1.6 3.5 3.5v17.9c.1 1.9-1.5 3.5-3.5 3.5zM3.5 2.2C2.1 2.2.9 3.4.9 4.8v17.9c0 1.5 1.2 2.6 2.6 2.6h14.6c1.5 0 2.6-1.2 2.6-2.6V4.9c0-1.4-1.2-2.6-2.6-2.6-.1 0-.2.1-.2.2s.1.2.2.2c1.2 0 2.2 1 2.2 2.2v17.9c0 1.2-1 2.2-2.2 2.2H3.5c-1.2 0-2.2-1-2.2-2.2V4.9c0-1.2 1-2.2 2.2-2.2 0 0 .1 0 .1-.1 0 0 .1-.1.1-.2s-.1-.2-.2-.2z"
        />
        <path
          d="M15.3 4c-.3 0-.5-.2-.5-.5V1c0-.3.2-.5.5-.5s.5.2.5.5v2.5c0 .3-.2.5-.5.5z"
        />
        <path
          d="M15.3 4.5c-.5 0-.8-.4-.8-1V1c0-.5.4-1 .8-1 .5 0 .8.4.8 1v2.5c0 .5-.3 1-.8 1zm0-3.6s-.1 0 0 0l-.1 2.6c0 .1.2.1.2 0L15.3.9c.1 0 0 0 0 0z"
        />
        <g>
          <path
            d="M6.2 4c-.2 0-.4-.2-.4-.5V1c0-.3.2-.5.5-.5s.4.2.4.5v2.5c0 .3-.2.5-.5.5z"
          />
          <path
            d="M6.2 4.5c-.5 0-.8-.4-.8-1V1c0-.5.4-1 .8-1 .5 0 .8.4.8 1v2.5c.1.5-.3 1-.8 1zm0-3.6v2.6c0 .1.2.1.2 0L6.2.9c.1 0 .1 0 0 0z"
          />
        </g>
        <g>
          <path
            d="M12.5 2.9H9.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.3c.3 0 .5.2.5.5s-.2.5-.5.5z"
          />
          <path
            d="M12.5 3.3H9.2c-.5 0-1-.4-1-1s.4-1 1-1h3.3c.5 0 1 .4 1 1s-.4 1-1 1zM9.2 2.2c-.1 0-.1.1 0 0-.1.1 0 .2 0 .2h3.3s.1 0 .1-.1c0 0 0-.1-.1-.1H9.2z"
          />
        </g>
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 18
    }
  }
};
</script>
